.app {
    height: 100%;
}

.app-header {
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 20;
}

.app-header-logo {
    height: 36px;
}