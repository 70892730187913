@font-face {
    font-family: "Anakotmai-Bold";
    /*Can be any text*/
    src: local("Anakotmai-Bold"),
        url("./fonts/Anakotmai-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: Bold;
}

@font-face {
    font-family: "Anakotmai-Medium";
    /*Can be any text*/
    src: local("Anakotmai-Medium"),
        url("./fonts/Anakotmai-Medium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Anakotmai-Light";
    /*Can be any text*/
    src: local("Anakotmai-Light"),
        url("./fonts/Anakotmai-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: lighter;
}

@font-face {
    font-family: "Pracharath-Regular";
    src: url("./fonts/Pracharath-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Pracharath-Bold";
    src: url("./fonts/Pracharath-Bold.otf") format("opentype");
    font-weight: 600;
    font-style: bold;
}

.app {
    font-family: "Anakotmai-Medium", sans-serif;
}